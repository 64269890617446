<template>
  <section class="first-section _container">
    <div class="content">
      <h1>Мы помогаем клиентам вернуть украденные деньги!</h1>
      <p>
        Используя уникальные методы взаимодействия с банками, криптобиржами и
        платежными системами, мы обеспечиваем возврат средств в 90% случаев.
        Обратитесь к нам, и получите бесплатную консультацию от наших
        опытных юристов.
      </p>
    </div>
    <div class="form_block">
      <ContactForm />
    </div>

  </section>
</template>

<script>
import ContactForm from '@/components/ContactForm.vue';

export default {
  name: 'HeroComponent',
  components: {
    ContactForm
  }
};
</script>

<style scoped>
.first-section {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 2rem;
}

.content {
  flex: 1;
  margin-right: 2rem;
}
.form_block {
  flex: 1;
}

.content h1 {
  font-size: 3rem;
  color: var(--text-color);
  margin-bottom: 1rem;
}

.content p {
  font-size: 1.25rem;
  color: var(--text-color);
}

/* Адаптивність */
@media screen and (max-width: 768px) {
  .first-section {
    flex-direction: column;
    align-items: center;
    margin: 10px 0;

  }

  .content {
    margin-right: 0;
    margin-bottom: 2rem;
    text-align: center;
  }
  .content h1{ 
    font-size: 2.3rem;
  }

  .form {
    width: 100%;
  }
}
</style>
