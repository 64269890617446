<template>
  <div id="app">
    <Navbar />
    <First />
    <Second />
    <Process />
    <Assistanse />
    <Advantages />
    <Team />
    <Testimonials />
    <Contacts />
    <Footer />
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue';
import Footer from './components/Footer.vue';
import First from './components/First.vue';
import Second from './components/Second.vue';
import Process from './components/Process.vue';
import Assistanse from './components/Assistanse.vue';
import Advantages from './components/Advantages.vue';
import Team from './components/Team.vue';
import Testimonials from './components/Testimonials.vue';
import Contacts from './components/Contacts.vue';


export default {
  components: {
    Navbar,
    First,
    Second,
    Process,
    Assistanse,
    Advantages,
    Team,
    Testimonials,
    Contacts,
    Footer
  }
};
</script>

<style>
/* Можна додати загальні стилі тут */
</style>
