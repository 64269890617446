<template>
  <div id="Form" class="container">
    <!-- Перший блок з формою -->
    <div class="heading-form">
      <form @submit.prevent="submitForm">
        <div class="form-group">
          <label for="name">Имя</label>
          <input 
            type="text" 
            id="name" 
            v-model="formData.name" 
            @blur="validateName" 
            :class="{ 'is-invalid': errors.name }"
            required 
          />
          <div v-if="errors.name" class="error-message">{{ errors.name }}</div>
        </div>

        <div class="form-group">
          <label for="phone">Номер телефона</label>
          <input 
            type="tel" 
            id="phone" 
            ref="phoneInput"
            v-model="formData.phone" 
            @blur="validatePhone" 
            :class="{ 'is-invalid': errors.phone }"
            required 
          />
          <div v-if="errors.phone" class="error-message">{{ errors.phone }}</div>
        </div>

        <div class="form-group">
          <label for="email">Email</label>
          <input 
            type="email" 
            id="email" 
            v-model="formData.email" 
            @blur="validateEmail" 
            :class="{ 'is-invalid': errors.email }"
            required 
          />
          <div v-if="errors.email" class="error-message">{{ errors.email }}</div>
        </div>

        <div class="form-group">
          <label for="case">Краткое описание проблемы</label>
          <textarea 
            id="case" 
            v-model="formData.case" 
            @blur="validateCase" 
            :class="{ 'is-invalid': errors.case }"
            rows="4" 
            required
          ></textarea>
          <div v-if="errors.case" class="error-message">{{ errors.case }}</div>
        </div>

        <div class="tab-button">
          <button type="submit" class="cta">Отправить</button>
        </div>
      </form>

      <!-- Повідомлення про успіх та помилки -->
      <div v-if="successMessage" class="success-message">{{ successMessage }}</div>
      <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
    </div>
  </div>
</template>

<script>
import emailjs from 'emailjs-com';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
import axios from 'axios';

export default {
  name: 'FormComponent',
  data() {
    return {
      formData: { 
        name: '', 
        phone: '', 
        email: '', 
        case: '' 
      },
      errors: {},
      successMessage: '',
      errorMessage: '',
    };
  },
  mounted() {
    const input = this.$refs.phoneInput;
    if (input) {
        intlTelInput.loadUtils("https://cdn.jsdelivr.net/npm/intl-tel-input@17.0.8/build/js/utils.js")
            .then(() => {
                this.iti = intlTelInput(input, {
                    separateDialCode: true,
                    initialCountry: "auto",
                    geoIpLookup: this.lookupIPLocation,
                });
                input.addEventListener('countrychange', this.onCountryChange);
            })
            .catch(error => {
                console.error('Error loading utils.js:', error);
            });
    } else {
        console.error('Phone input reference not found.');
    }
  },
  methods: {
    lookupIPLocation(callback) {
      axios.get('https://ipapi.co/json/')
        .then(response => {
          const countryCode = response.data.country_code;
          callback(countryCode);
        })
        .catch(error => {
          console.error('Error fetching location:', error);
          callback('us');
        });
    },
    onCountryChange() {
      const selectedCountryData = this.iti.getSelectedCountryData();
      console.log('Selected country code:', selectedCountryData.dialCode);
    },

    validateName() {
      this.errors.name = this.formData.name ? '' : 'Введите имя';
    },
    validateEmail() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      this.errors.email = emailRegex.test(this.formData.email) ? '' : 'Некоректный адрес';
    },
    validateCase() {
      this.errors.case = this.formData.case ? '' : 'Сообщение не отправлено';
    },
    submitForm() {
      this.validateName();
      this.validateEmail();
      this.validateCase();

      if (Object.values(this.errors).every(error => !error)) {
        const userAgent = navigator.userAgent;
        const platform = navigator.platform;
        const language = navigator.language;
        let locationInfo = 'Location not provided';

        navigator.geolocation.getCurrentPosition(position => {
          locationInfo = `Latitude: ${position.coords.latitude}, Longitude: ${position.coords.longitude}`;
          const templateParams = this.getTemplateParams(userAgent, platform, language, locationInfo);
          this.sendForm(templateParams);
        }, error => {
          console.warn('Location access denied:', error);
          const templateParams = this.getTemplateParams(userAgent, platform, language, locationInfo);
          this.sendForm(templateParams);
        });
      } else {
        this.successMessage = '';
        this.errorMessage = 'Please correct the errors in the form before submitting.';
      }
    },
    getTemplateParams(userAgent, platform, language, locationInfo) {
      return {
        name: this.formData.name,
        email: this.formData.email,
        countryCode: this.iti.getSelectedCountryData().dialCode,
        phone: this.formData.phone,
        case: this.formData.case,
        userAgent,
        platform,
        language,
        location: locationInfo
      };
    },
    sendForm(templateParams) {
      emailjs.send('service_8cp3tya', 'template_ulidzha', templateParams, 'ziMoR2JSykXK6Lmmj')
        .then(response => {
          console.log('Form submitted successfully:', response);
          this.formData = { name: '', phone: '', email: '', case: '' };
          this.successMessage = 'Заявка успешно отправлена!';
          this.errorMessage = '';
        })
        .catch(error => {
          console.error('Error submitting form:', error);
          this.successMessage = '';
          this.errorMessage = 'Проблема отрправки! Попробуйте позже..';
        });
    }
  }
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.heading-form {
  padding: 1rem;
}

.form-group {
  margin-bottom: 1rem;
}

input, textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

input.is-invalid, textarea.is-invalid {
  border-color: #dc3545;
}

.error-message {
  color: #dc3545;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

.success-message {
  color: #28a745;
  font-size: 1rem;
  margin-top: 1rem;
}

.tab-button {
  margin-top: 1rem;

}

.cta {
  background-color:var(--text-color);
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.cta:hover {
  background-color: var(--accent-color);
}

.payment-icons {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.payment-icons img {
  width: auto;
  max-width: 100px;
}

@media (max-width: 768px) {
  .payment-icons {
    justify-content: center;
    margin: 0 50px;
  }

  .payment-icons img {
    max-width: 80px;
  }
  .heading-form {
    padding:0;
  }
  .form-group {
  margin-bottom: 1rem;
  }

  .form-group input{
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  .cta {
    font-size: 1.5rem;
    width: 100%;
    border-radius: 6px;
  }
}
</style>
